import { SortableContext, useSortable } from "@dnd-kit/sortable";
import AddNewLead from "../../components/board/AddLead";
import LeadCard from "../../components/board/LeadCard";
import { FaPlus, FaSort } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { useMemo, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import Papa from 'papaparse';
import useSWR from "swr";
const Column = ({ column, leads, loadingLeads, leadsMutate, isWithinLastMonth, selectedTab, source }) => {
    const totalLeads = leads.length;
    const [isOpenNewLead, setOpenNewLeadPopup] = useState(false);
    const [sortDirection, setSortDirection] = useState("desc");
    const sortedLeads = useMemo(() => {
        const sorted = [...leads].sort((a, b) => {
            const dateA = new Date(a.joiningDate || 0);
            const dateB = new Date(b.joiningDate || 0);
            return sortDirection === "asc"
                ? dateA - dateB
                : dateB - dateA;
        });
        return sorted;
    }, [leads, sortDirection]);
    const handleSortToggle = () => {
        setSortDirection((prev) => (prev === "desc" ? "asc" : "desc"));
    };

    const leadIds = useMemo(() => {
        return leads.map((lead, index) => index);
    }, [leads]);
    const { setNodeRef, transform, transition } = useSortable({
        id: column.id,
        data: {
            type: "Column",
            column,
        },
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };
    const downloadCSV = () => {
        const csvData = leads.map(lead => ({
            'Full Name': lead.fullName,
            'Email': lead.email,
            'Phone': lead.phone,
            'Status': lead.status,
        }));
        const csvString = Papa.unparse(csvData);
        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
        downloadLink.target = '_blank';
        downloadLink.download = `${column.title}_leads.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    const { data: leadData } = useSWR(
        leads.length > 0 && leads[0]?.email
            ? `/crm/${leads[0]?.email}?source=${leads[0]?.source}`
            : null
    );

    const memoizedData = useMemo(() => {
        return leadData ? { ...leadData[leads[0]?.email], notes: leadData.notes } : null;
    }, [leadData, leads]);

    console.log("Memoized Data:", memoizedData);
    return (
        <div
            ref={setNodeRef}
            style={style}
            className="bg-background-1  w-full lg:w-auto h-[75vh] flex flex-grow flex-col rounded-lg px-2"
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <h2 className="leading-normal text-sm font-normal py-2.5 text-heading-1">
                        {column.id === "SALE" && selectedTab === "All Leads" ? "WIN" : column.title}
                    </h2>
                    <button
                        onClick={handleSortToggle}
                        className="text-heading-1 text-sm font-semibold flex items-center gap-1"
                    >
                        {sortDirection === "asc" ? <FaSort className="text-gray-500" /> : <FaSort />}
                    </button>
                    <div className="bg-[#BDBDBD]  w-6 h-6 text-xs rounded-full flex justify-center items-center dark:text-black">{totalLeads}</div>
                </div>
                <div className="flex items-center gap-2">
                    <button
                        onClick={downloadCSV}
                        className="text-heading-1 text-sm font-semibold px-1 bg-background-primary leading-normal flex gap-2 items-center rounded-md py-1"
                    >
                        <FiDownload className="text-primary h-5 w-5" />
                    </button>
                    <button
                        onClick={() => setOpenNewLeadPopup(true)}
                        className="text-heading-1 text-sm px-1 bg-background-primary font-semibold leading-normal flex gap-2 items-center rounded-md py-1"
                    >
                        <FaPlus className="text-primary h-5 w-5" />
                    </button>

                </div>

            </div>

            <div className="flex flex-grow  flex-col gap-4 overflow-x-hidden overflow-y-auto no-scrollbar w-full">
                <SortableContext items={leadIds}>
                    {sortedLeads.map((lead) => (
                        <LeadCard
                            selectedTab={selectedTab}
                            key={lead.email}
                            lead={lead}
                            memoizedData={memoizedData}
                            email={lead?.email}
                            loadingLeads={loadingLeads}
                            leadsMutate={leadsMutate}
                            columnId={column.id}
                            isWithinLastMonth={isWithinLastMonth}
                        />
                    ))}
                </SortableContext>
            </div>
            <AddNewLead
                source={source}
                isOpen={isOpenNewLead}
                leadsMutate={leadsMutate}
                status={column?.id}
                close={() => setOpenNewLeadPopup(false)}
            />
        </div>
    );
};
export default Column;